
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import * as sort from '@/helpers/sort'
import getFallbackDiagram from '@/modules/editor/helpers/fallback-diagram'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { DiagramModule } from '../../store'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem
  },
  name: 'DiagramContextMenuUpdateGroup'
})
export default class DiagramContextMenuUpdateGroup extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly diagramId!: string
  @Prop({ default: () => {} }) readonly menuProps!: any

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentModel () {
    return this.modelModule.objects[this.diagram.modelId]
  }

  get currentModelParent () {
    return this.currentModel?.parentId ? this.modelModule.objects[this.currentModel.parentId] : undefined
  }

  get diagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  get diagramGroups () {
    return Object
      .values(this.diagramModule.diagramGroups)
      .filter(o => o.modelId === this.currentModel?.id)
      .sort(sort.index)
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    } else if (this.diagram.status === 'draft') {
      return 'You cannot change draft group'
    }
  }

  async updateDiagramGroup (id: string, groupId: string | null) {
    if (!this.currentModel) {
      return
    }

    const fallbackDiagram = getFallbackDiagram({
      diagram: this.diagram,
      diagramGroupId: this.diagram.groupId || undefined,
      diagramGroups: Object.values(this.diagramModule.diagramGroups),
      diagrams: Object.values(this.diagramModule.diagrams).map(o => o.id === id ? { ...o, groupId } : o),
      model: this.currentModel,
      modelParent: this.currentModelParent
    })

    if (this.diagram.id === id) {
      if (fallbackDiagram) {
        await this.$replaceQuery({
          connection: undefined,
          diagram: fallbackDiagram.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_step: undefined,
          model: fallbackDiagram.model.handleId,
          object: undefined,
          scale: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      } else {
        await this.$router.push({
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          }
        })
      }
    }

    const nextIndex = Object
      .values({
        ...this.diagramModule.diagrams,
        ...this.diagramModule.diagramGroups
      })
      .filter(o => o.modelId === this.currentModel?.id && ('groupId' in o ? o.groupId === groupId : groupId === null))
      .reduce((p, c) => c.index > p ? c.index : p, -1) + 1

    const revertTasks: Task[] = [{
      id,
      props: {
        groupId: this.diagramModule.diagrams[id].groupId,
        index: this.diagramModule.diagrams[id].index
      },
      type: 'diagram-update'
    }]

    const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(id, {
      groupId,
      index: nextIndex
    })
    this.diagramModule.setDiagramVersion(diagram)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpdate({
        diagramId: id,
        landscapeId: this.currentLandscape.id,
        props: diagramUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks: [
        ...revertTasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ],
      tasks: [
        {
          id,
          props: diagramUpdate,
          type: 'diagram-update'
        },
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }
}
