var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ContextMenu',{ref:"menuRef",attrs:{"value":_vm.value,"menu-props":_vm.menuProps},on:{"input":function($event){return _vm.$emit('input', $event)},"open":function($event){return _vm.$emit('open', $event)},"close":function($event){return _vm.$emit('close', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(activator){return [_vm._t("activator",null,null,activator)]}}],null,true)},[_c('ContextMenuContent',[_c('UpdateDiagramGroup',{attrs:{"diagram-id":_vm.diagramId,"menu-props":{
        nudgeLeft: 0,
        nudgeRight: 0,
        nudgeTop: 9
      }}}),_c('PinDiagram',{attrs:{"diagram-id":_vm.diagramId}}),_c('DuplicateDiagram',{attrs:{"diagram-id":_vm.diagramId},on:{"click":function($event){return _vm.menuRef.close()}}}),_c('UpdateDiagramParent',{attrs:{"diagram-id":_vm.diagramId,"menu-props":{
        nudgeLeft: 0,
        nudgeRight: 0,
        nudgeTop: 9
      }},on:{"click":function($event){return _vm.menuRef.close()}}}),_c('DeleteDiagram',{attrs:{"diagram-id":_vm.diagramId},on:{"click":function($event){return _vm.menuRef.close()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }