
import { DiagramType, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import findNextName from '@/helpers/find-next-name'
import * as sort from '@/helpers/sort'
import * as diagramAnalytics from '@/modules/diagram/helpers/analytics'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { EditorModule } from '../../../editor/store'
import DiagramContextMenu from '../diagram-context-menu/index.vue'
import DiagramNameEdit from '../name-edit.vue'
import NameEdit from './name-edit.vue'

@Component({
  components: {
    DiagramContextMenu,
    DiagramNameEdit,
    Draggable,
    NameEdit
  },
  name: 'DiagramGroupDiagramsList'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly diagramGroupId!: string

  expanded = false
  editing = false
  editingDiagramId: string | null = null
  optionsMenuItemId: string | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get diagramDeleteDialog () {
    return this.$queryValue('diagram_delete_dialog')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentDiagramGroup () {
    return this.diagramModule.diagramGroups[this.diagramGroupId]
  }

  get currentModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get items () {
    return Object
      .values(this.diagramModule.diagrams)
      .filter(o => this.currentModel && o.modelId === this.currentModel.id && o.groupId === this.currentDiagramGroup.id)
      .map(o => ({
        active: this.currentDiagramHandleId === o.handleId,
        click: () => {
          if (this.currentDiagramHandleId !== o.handleId && !this.editingDiagramId) {
            this.$pushQuery({
              diagram: o.handleId,
              flow: undefined,
              flow_parent: undefined,
              flow_path: undefined,
              flow_step: undefined,
              object: undefined,
              x1: undefined,
              x2: undefined,
              y1: undefined,
              y2: undefined
            })
          }
          this.$emit('select-diagram', o.id)
        },
        handleId: o.handleId,
        id: o.id,
        index: o.index,
        name: o.name
      }))
      .sort(sort.index)
  }

  get nextItemIndex () {
    return Object
      .values(this.items)
      .reduce((p, c) => c.index > p ? c.index : p, -1) + 1
  }

  mounted () {
    if (this.currentDiagram.groupId === this.diagramGroupId || this.diagramModule.diagramGroupsExpanded[this.diagramGroupId]) {
      this.expanded = true
    }
  }

  blur () {
    this.editingDiagramId = null
    this.optionsMenuItemId = null
  }

  newDiagram () {
    if (!this.currentModel) {
      return
    }

    const diagramNames: Record<DiagramType, string> = {
      'app-diagram': 'App Diagram',
      'component-diagram': 'Component Diagram',
      'context-diagram': 'Context Diagram'
    }
    const diagramPrefix = this.currentDiagram.type === 'context-diagram' ? diagramNames[this.currentDiagram.type] : `${this.currentModel.name} ${diagramNames[this.currentDiagram.type]}`
    const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(this.currentLandscape.id, this.currentVersion.id, {
      groupId: this.currentDiagramGroup.id,
      index: this.nextItemIndex,
      modelId: this.currentModel.id,
      name: findNextName(diagramPrefix, this.items),
      type: this.currentDiagram.type
    })
    const { diagramContent, diagramContentUpsert } = this.diagramModule.generateDiagramContent(this.currentLandscape.id, this.currentVersion.id, diagram, {})

    this.diagramModule.setDiagramVersion(diagram)
    this.diagramModule.setDiagramContentVersion(diagramContent)

    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpsert({
        diagramId: diagram.id,
        landscapeId: this.currentLandscape.id,
        props: {
          ...diagramUpsert,
          ...diagramContentUpsert
        },
        updateViewedAt: true,
        versionId: this.currentVersion.id
      })
    })

    const tasks: Task[] = [{
      id: diagram.id,
      props: {
        ...diagramUpsert,
        ...diagramContentUpsert
      },
      type: 'diagram-create'
    }]
    const revertTasks: Task[] = [{
      route: this.$route,
      type: 'navigation'
    }, {
      id: diagram.id,
      type: 'diagram-delete'
    }]

    this.$pushQuery({
      comment: undefined,
      connection: undefined,
      diagram: diagram.handleId,
      flow: undefined,
      flow_parent: undefined,
      flow_path: undefined,
      flow_step: undefined,
      object: undefined,
      x1: undefined,
      x2: undefined,
      y1: undefined,
      y2: undefined
    })

    this.$emit('create-diagram', diagram.id)

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })

    diagramAnalytics.diagramCreate.track(this, {
      diagramType: diagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  updateIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      const itemsOrdered = [...this.items]
      const element = itemsOrdered[moved.oldIndex]
      itemsOrdered.splice(moved.oldIndex, 1)
      itemsOrdered.splice(Math.max(0, moved.newIndex), 0, element)

      const func: (() => Promise<any>)[] = []
      const tasks: Task[] = []
      const revertTasks: Task[] = []

      itemsOrdered.forEach((o, index) => {
        if (o.index !== index) {
          revertTasks.push({
            id: o.id,
            props: {
              index: o.index
            },
            type: 'diagram-update'
          })

          const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(o.id, {
            index
          })
          this.diagramModule.setDiagramVersion(diagram)

          func.push(() => this.diagramModule.diagramUpdate({
            diagramId: o.id,
            landscapeId: this.currentLandscape.id,
            props: diagramUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: diagram.id,
            props: diagramUpdate,
            type: 'diagram-update'
          })
        }
      })

      this.editorModule.addToTaskQueue({ func })

      this.editorModule.addTaskList({
        revertTasks: [{
          tasks: revertTasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }],
        tasks: [{
          tasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }

  toggleExpanded () {
    if (!this.editing) {
      this.expanded = !this.expanded

      this.diagramModule.setDiagramGroupsExpanded({
        [this.diagramGroupId]: this.expanded
      })
    }
  }
}
