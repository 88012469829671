
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import * as sort from '@/helpers/sort'
import { AlertModule } from '@/modules/alert/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { DiagramModule } from '../../store'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    RecycleScroller
  },
  name: 'DiagramContextMenuUpdateParent'
})
export default class DiagramContextMenuUpdateParent extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly diagramId!: string
  @Prop({ default: () => {} }) readonly menuProps!: any

  @Ref() readonly systemsMenuRef?: ContextMenu
  @Ref() readonly appOrStoreMenuRef?: ContextMenu

  systemSearchModel = ''
  appOrStoreSearchModel = ''

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  get currentDiagramModel () {
    return this.modelModule.objects[this.currentDiagram.modelId]
  }

  get currentModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentModelParent () {
    return this.currentModel?.parentId ? this.modelModule.objects[this.currentModel.parentId] : undefined
  }

  get diagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  get diagramContent () {
    return this.diagramModule.diagramContents[this.diagramId]
  }

  get diagramFlows () {
    return Object.values(this.flowModule.flows).filter(o => o.diagramId === this.diagram.id)
  }

  get diagramGroups () {
    return Object.values(this.diagramModule.diagramGroups).filter(o => o.modelId === this.currentModel?.id).sort(sort.index)
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentDomain () {
    return this.domains.find(o => o.handleId === this.currentDomainHandleId)
  }

  get domainsFiltered () {
    return this.domains.sort(sort.index)
  }

  get systems () {
    return Object.values(this.modelModule.objects).filter(o => o.type === 'system')
  }

  get systemsFiltered () {
    return this.systems.filter(o => !this.systemSearchModel || o.name.toLowerCase().includes(this.systemSearchModel.toLowerCase())).sort(sort.index)
  }

  get appsAndStores () {
    return Object.values(this.modelModule.objects).filter(o => o.type === 'app' || o.type === 'store')
  }

  get appsAndStoresFiltered () {
    return this.appsAndStores.filter(o => !this.appOrStoreSearchModel || o.name.toLowerCase().includes(this.appOrStoreSearchModel.toLowerCase())).sort(sort.index)
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    } else if (this.diagram.status === 'draft') {
      return 'You cannot change draft parents'
    }
  }

  @Watch('systemSearchModel')
  onSystemSearchModelChanged () {
    this.systemsMenuRef?.updateDimensions()
  }

  @Watch('appOrStoreSearchModel')
  onAppOrStoreSearchModelhanged () {
    this.appOrStoreMenuRef?.updateDimensions()
  }

  clearSearch () {
    this.systemSearchModel = ''
    this.appOrStoreSearchModel = ''
  }

  async moveDiagramDomain (domainId: string) {
    const domainModelObjectRoot = Object.values(this.modelModule.objects).find(o => o.type === 'root' && o.domainId === domainId)
    if (!domainModelObjectRoot) {
      throw new Error('Could not find domain model object root')
    }

    const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(this.currentDiagram.id, {
      groupId: null,
      modelId: domainModelObjectRoot.id
    })
    this.diagramModule.setDiagramVersion(diagram)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpdate({
        diagramId: this.currentDiagram.id,
        landscapeId: this.currentLandscape.id,
        props: diagramUpdate,
        versionId: this.currentVersion.id
      })
    })

    if (this.$route.name?.endsWith('-diagram')) {
      const routeName = this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram'
      this.$router.replace({
        name: routeName,
        params: {
          landscapeId: this.currentLandscapeId ?? ' ',
          versionId: this.currentVersionId
        },
        query: {
          diagram: diagram.handleId,
          model: domainModelObjectRoot.handleId
        }
      })
      this.$emit('close-menu')
    } else {
      const domain = Object.values(this.domainModule.domains).find(o => o.id === domainId)
      this.$pushQuery({
        domain: domain?.handleId
      })
    }

    this.alertModule.pushAlert({
      message: `Diagram moved to ${domainModelObjectRoot.name}`
    })

    analytics.diagramMove.track(this, {
      diagramType: this.currentDiagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async moveDiagram (modelId: string) {
    const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(this.currentDiagram.id, {
      groupId: null,
      modelId
    })
    this.diagramModule.setDiagramVersion(diagram)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpdate({
        diagramId: this.currentDiagram.id,
        landscapeId: this.currentLandscape.id,
        props: diagramUpdate,
        versionId: this.currentVersion.id
      })
    })
    this.alertModule.pushAlert({
      message: `Diagram moved to ${this.modelModule.objects[modelId].name}`
    })

    this.$emit('close-menu')

    analytics.diagramMove.track(this, {
      diagramType: this.currentDiagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
