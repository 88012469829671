
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'

import DeleteDiagram from './delete-diagram.vue'
import DuplicateDiagram from './duplicate-diagram.vue'
import PinDiagram from './pin-diagram.vue'
import UpdateDiagramGroup from './update-diagram-group.vue'
import UpdateDiagramParent from './update-diagram-parent.vue'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    DeleteDiagram,
    DuplicateDiagram,
    PinDiagram,
    UpdateDiagramGroup,
    UpdateDiagramParent
  },
  name: 'DiagramContextMenu'
})
export default class DiagramContextMenu extends Vue {
  @Prop() readonly diagramId!: string
  @Prop() readonly menuProps?: any
  @Prop() readonly value?: any

  @Ref() readonly menuRef!: ContextMenu
}
