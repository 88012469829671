
import { DiagramType, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import findNextName from '@/helpers/find-next-name'
import * as sort from '@/helpers/sort'
import NameEdit from '@/modules/diagram/components/name-edit.vue'
import * as diagramAnalytics from '@/modules/diagram/helpers/analytics'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { EditorModule } from '../../editor/store'
import DiagramContextMenu from './diagram-context-menu/index.vue'
import DiagramsList from './group/diagrams-list.vue'
import ThumbnailMenu from './thumbnail-menu.vue'

@Component({
  components: {
    DiagramContextMenu,
    DiagramsList,
    Draggable,
    NameEdit,
    OrganizationUpgradeMenu,
    ThumbnailMenu
  },
  name: 'DiagramMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly diagramMenuRef!: any
  @Ref() readonly listRef!: any
  @Ref() readonly diagramListRef?: DiagramsList[]

  @Prop() readonly permission!: PermissionType

  editingDiagramId: string | null = null
  optionsMenuItemId: string | null = null
  hoverDiagramId: string | null = null
  menuVisible = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get diagramDeleteDialog () {
    return this.$queryValue('diagram_delete_dialog')
  }

  get diagramGroupDeleteDialog () {
    return this.$queryValue('diagram_group_delete_dialog')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentDiagrams () {
    const currentModel = this.currentModel
    return Object.values(this.diagramModule.diagrams).filter(o => currentModel && o.modelId === currentModel.id && !o.groupId && !o.parentId)
  }

  get currentDiagramGroups () {
    const currentModel = this.currentModel
    return Object.values(this.diagramModule.diagramGroups).filter(o => currentModel && o.modelId === currentModel.id)
  }

  get diagramGroupsLimitReached () {
    return this.organizationLimits.diagramGroups <= Object.values(this.diagramModule.diagramGroups).length
  }

  get items () {
    const items = [
      ...this.currentDiagrams,
      ...this.currentDiagramGroups
    ] as const
    return items
      .map(o => ({
        active: this.currentDiagram.id === o.id || ('parentId' in o && o.id === this.currentDiagram.parentId),
        click: async () => {
          if (this.currentDiagram.id !== o.id && 'type' in o && !this.editingDiagramId) {
            const explore = await this.diagramModule.diagramsExplore({
              diagramId: o.id,
              landscapeId: this.currentLandscapeId,
              modelId: o.modelId,
              versionId: this.currentVersionId
            })
            if (explore) {
              this.diagramModule.diagramAction({
                action: 'zoom',
                diagramId: explore.diagram.id,
                landscapeId: this.currentLandscapeId,
                versionId: this.currentVersionId
              })

              await this.$pushQuery({
                connection: undefined,
                diagram: explore.diagram.handleId,
                flow: undefined,
                flow_parent: undefined,
                flow_path: undefined,
                flow_step: undefined,
                model: explore.modelObject.handleId,
                object: undefined,
                x1: undefined,
                x2: undefined,
                y1: undefined,
                y2: undefined
              })
            }
            this.close()
          }
        },
        handleId: o.handleId,
        id: o.id,
        index: o.index,
        name: o.name,
        type: 'type' in o ? o.type : 'group' as const
      }))
      .sort(sort.index)
  }

  get nextItemIndex () {
    return Object
      .values(this.items)
      .reduce((p, c) => c.index > p ? c.index : p, -1) + 1
  }

  loadThumbnails () {
    this.diagramModule.diagramThumbnailsList({
      filter: {
        modelId: this.currentDiagram.modelId
      },
      landscapeId: this.currentLandscapeId,
      versionId: this.currentVersionId
    })
  }

  handleMenuClose () {
    this.menuVisible = false
  }

  close () {
    this.diagramMenuRef.isActive = false
  }

  blur () {
    this.editingDiagramId = null
    this.optionsMenuItemId = null
    this.hoverDiagramId = null
    this.diagramListRef?.forEach(o => o.blur())
  }

  async newDiagramGroup () {
    if (!this.currentModel) {
      return
    }

    const { diagramGroup, diagramGroupUpsert } = this.diagramModule.generateDiagramGroup(this.currentLandscape.id, this.currentVersion.id, {
      index: this.nextItemIndex,
      modelId: this.currentModel.id,
      name: findNextName('Group', this.items)
    })
    this.diagramModule.setDiagramGroupVersion(diagramGroup)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramGroupUpsert({
        diagramGroupId: diagramGroup.id,
        landscapeId: this.currentLandscape.id,
        props: diagramGroupUpsert,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks: [
        {
          route: this.$route,
          type: 'navigation'
        }, {
          id: diagramGroup.id,
          type: 'diagram-group-delete'
        }
      ],
      tasks: [
        {
          id: diagramGroup.id,
          props: diagramGroupUpsert,
          type: 'diagram-group-create'
        },
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })

    diagramAnalytics.diagramGroupCreate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })

    await this.$nextTick()

    const diagramListItem = this.diagramListRef?.find(o => o.diagramGroupId === diagramGroup.id)
    if (diagramListItem) {
      diagramListItem.editing = true
    }
  }

  async newDiagram () {
    if (!this.currentModel) {
      return
    }

    const diagramNames: Record<DiagramType, string> = {
      'app-diagram': 'App Diagram',
      'component-diagram': 'Component Diagram',
      'context-diagram': 'Context Diagram'
    }
    const diagramPrefix = this.currentDiagram.type === 'context-diagram' ? diagramNames[this.currentDiagram.type] : `${this.currentModel.name} ${diagramNames[this.currentDiagram.type]}`
    const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(this.currentLandscape.id, this.currentVersion.id, {
      groupId: null,
      index: this.nextItemIndex,
      modelId: this.currentModel.id,
      name: findNextName(diagramPrefix, this.items),
      type: this.currentDiagram.type
    })
    const { diagramContent, diagramContentUpsert } = this.diagramModule.generateDiagramContent(this.currentLandscape.id, this.currentVersion.id, diagram, {})

    this.diagramModule.setDiagramVersion(diagram)
    this.diagramModule.setDiagramContentVersion(diagramContent)

    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpsert({
        diagramId: diagram.id,
        landscapeId: this.currentLandscape.id,
        props: {
          ...diagramUpsert,
          ...diagramContentUpsert
        },
        updateViewedAt: true,
        versionId: this.currentVersion.id
      })
    })

    const tasks: Task[] = [{
      id: diagram.id,
      props: {
        ...diagramUpsert,
        ...diagramContentUpsert
      },
      type: 'diagram-create'
    }]
    const revertTasks: Task[] = [{
      route: this.$route,
      type: 'navigation'
    }, {
      id: diagram.id,
      type: 'diagram-delete'
    }]

    const explore = await this.diagramModule.diagramsExplore({
      diagramId: diagram.id,
      landscapeId: this.currentLandscape.id,
      modelId: diagram.modelId,
      versionId: this.currentVersion.id
    })
    if (explore) {
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramAction({
          action: 'zoom',
          diagramId: explore.diagram.id,
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        })
      })

      await this.$pushQuery({
        comment: undefined,
        connection: undefined,
        diagram: explore.diagram.handleId,
        flow: undefined,
        flow_parent: undefined,
        flow_path: undefined,
        flow_step: undefined,
        model: explore.modelObject.handleId,
        object: undefined,
        x1: undefined,
        x2: undefined,
        y1: undefined,
        y2: undefined
      })
    }

    this.close()

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })

    diagramAnalytics.diagramCreate.track(this, {
      diagramType: diagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  updateIndex ({ moved }: { moved?: { newIndex: number, oldIndex: number } }) {
    if (moved) {
      const itemsOrdered = [...this.items]
      const element = itemsOrdered[moved.oldIndex]
      itemsOrdered.splice(moved.oldIndex, 1)
      itemsOrdered.splice(Math.max(0, moved.newIndex), 0, element)

      const func: (() => Promise<any>)[] = []
      const tasks: Task[] = []
      const revertTasks: Task[] = []

      itemsOrdered.forEach((o, index) => {
        if (o.index !== index) {
          if (o.type === 'group') {
            revertTasks.push({
              id: o.id,
              props: {
                index: o.index
              },
              type: 'diagram-group-update'
            })

            const { diagramGroup, diagramGroupUpdate } = this.diagramModule.generateDiagramGroupCommit(o.id, {
              index
            })
            this.diagramModule.setDiagramGroupVersion(diagramGroup)

            func.push(() => this.diagramModule.diagramGroupUpdate({
              diagramGroupId: o.id,
              landscapeId: this.currentLandscape.id,
              props: diagramGroupUpdate,
              versionId: this.currentVersion.id
            }))

            tasks.push({
              id: diagramGroup.id,
              props: diagramGroupUpdate,
              type: 'diagram-group-update'
            })
          } else {
            revertTasks.push({
              id: o.id,
              props: {
                index: o.index
              },
              type: 'diagram-update'
            })

            const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(o.id, {
              index
            })
            this.diagramModule.setDiagramVersion(diagram)

            func.push(() => this.diagramModule.diagramUpdate({
              diagramId: o.id,
              landscapeId: this.currentLandscape.id,
              props: diagramUpdate,
              versionId: this.currentVersion.id
            }))

            tasks.push({
              id: diagram.id,
              props: diagramUpdate,
              type: 'diagram-update'
            })
          }
        }
      })

      this.editorModule.addToTaskQueue({ func })

      this.editorModule.addTaskList({
        revertTasks: [{
          tasks: revertTasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }],
        tasks: [{
          tasks,
          type: 'batch'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }
}
