
import { FormController, validationRules } from '@icepanel/app-form'
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'

import { DiagramModule } from '../../store'

@Component({
  name: 'DiagramGroupNameEdit'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)

  @Prop() readonly diagramGroupId!: string

  @Ref() readonly textFieldRef!: { $refs: { input: HTMLInputElement } }

  get diagramGroup () {
    return this.diagramModule.diagramGroups[this.diagramGroupId]
  }

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      const revertTasks: Task[] = [{
        id: this.diagramGroup.id,
        props: {
          name: this.diagramGroup.name
        },
        type: 'diagram-group-update'
      }]

      const { diagramGroup, diagramGroupUpdate } = this.diagramModule.generateDiagramGroupCommit(this.diagramGroup.id, {
        name: model.name
      })
      this.diagramModule.setDiagramGroupVersion(diagramGroup)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramGroupUpdate({
          diagramGroupId: this.diagramGroup.id,
          landscapeId: this.diagramGroup.landscapeId,
          props: diagramGroupUpdate,
          versionId: this.diagramGroup.versionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: this.diagramGroup.id,
          props: diagramGroupUpdate,
          type: 'diagram-group-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })

    this.formController.setInitialModel({
      name: this.diagramGroup.name
    })
    this.formController.resetModel()
  }
}
