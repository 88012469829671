
import { DiagramConnection, DiagramObject, FlowStep, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import randomId from '@/helpers/random-id'
import { AlertModule } from '@/modules/alert/store'
import * as editorAnalytics from '@/modules/editor/helpers/analytics'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { DiagramModule } from '../../store'

@Component({
  components: {
    ContextMenuItem
  },
  name: 'DiagramContextMenuDuplicateDiagram'
})
export default class DiagramContextMenuDuplicateDiagram extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly diagramId!: string

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  get currentDiagramModel () {
    return this.modelModule.objects[this.currentDiagram.modelId]
  }

  get currentModel () {
    return this.modelModule.objects[this.diagram.modelId]
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get diagram () {
    return this.diagramModule.diagrams[this.diagramId]
  }

  get diagramContent () {
    return this.diagramModule.diagramContents[this.diagramId]
  }

  get diagramFlows () {
    return Object.values(this.flowModule.flows).filter(o => o.diagramId === this.diagram.id)
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    } else if (this.diagram.status === 'draft') {
      return 'You cannot duplicate drafts'
    }
  }

  async duplicateDiagram (withFlows: boolean) {
    if (!this.currentModel) {
      return
    }

    const currentDiagramContent = await this.diagramModule.diagramContentFind({
      diagramId: this.diagram.id,
      landscapeId: this.diagram.landscapeId,
      versionId: this.diagram.versionId
    })

    const tasks: Task[] = []
    const revertTasks: Task[] = [{
      route: this.$route,
      type: 'navigation'
    }]

    const replaceIdMap: Record<string, string> = {}
    const replaceId = (id: string) => {
      if (!replaceIdMap[id]) {
        replaceIdMap[id] = randomId()
      }
      return replaceIdMap[id]
    }

    const connections = Object.values(currentDiagramContent.connections).reduce<Record<string, DiagramConnection>>((p, c) => ({
      ...p,
      [replaceId(c.id)]: {
        ...c,
        id: replaceId(c.id),
        originId: c.originId ? replaceId(c.originId) : null,
        targetId: c.targetId ? replaceId(c.targetId) : null
      }
    }), {})

    const objects = Object.values(currentDiagramContent.objects).reduce<Record<string, DiagramObject>>((p, c) => ({
      ...p,
      [replaceId(c.id)]: {
        ...c,
        id: replaceId(c.id)
      }
    }), {})

    const { diagram, diagramUpsert } = this.diagramModule.generateDiagram(this.currentLandscape.id, this.currentVersion.id, {
      description: this.diagram.description,
      groupId: this.diagram.groupId,
      index: this.diagram.index + 1,
      modelId: this.currentModel.id,
      name: `${this.diagram.name} copy`,
      type: this.diagram.type
    })
    const { diagramContent, diagramContentUpsert } = this.diagramModule.generateDiagramContent(this.currentLandscape.id, this.currentVersion.id, diagram, {
      connections,
      objects
    })

    this.diagramModule.setDiagramVersion(diagram)
    this.diagramModule.setDiagramContentVersion(diagramContent)

    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramUpsert({
        diagramId: diagram.id,
        landscapeId: this.currentLandscape.id,
        props: {
          ...diagramUpsert,
          ...diagramContentUpsert
        },
        updateViewedAt: true,
        versionId: this.currentVersion.id
      })
    })

    tasks.push({
      id: diagram.id,
      props: {
        ...diagramUpsert,
        ...diagramContentUpsert
      },
      type: 'diagram-create'
    })

    if (withFlows) {
      const func: (() => Promise<any>)[] = []

      this.diagramFlows.forEach(o => {
        const { flow, flowUpsert } = this.flowModule.generateFlow(o.landscapeId, o.versionId, {
          diagramId: diagram.id,
          labels: o.labels,
          name: o.name,
          showConnectionNames: o.showConnectionNames,
          steps: Object.entries(o.steps).reduce<Record<string, FlowStep>>((p, [id, s]) => ({
            ...p,
            [replaceId(id)]: {
              ...s,
              id: replaceId(id),
              originId: s.originId ? replaceId(s.originId) : null,
              pathId: s.pathId ? replaceId(s.pathId) : null,
              targetId: s.targetId ? replaceId(s.targetId) : null,
              viaId: s.viaId ? replaceId(s.viaId) : null
            }
          }), {})
        })
        this.flowModule.setFlowVersion(flow)

        func.push(() => this.flowModule.flowUpsert({
          flowId: flow.id,
          landscapeId: this.currentLandscape.id,
          props: flowUpsert,
          versionId: this.currentVersion.id
        }))

        tasks.push({
          id: flow.id,
          props: flowUpsert,
          type: 'flow-create'
        })
        revertTasks.push({
          id: flow.id,
          type: 'flow-delete'
        })
      })

      this.editorModule.addToTaskQueue({ func })
    }

    revertTasks.push({
      id: diagram.id,
      type: 'diagram-delete'
    })

    this.$emit('duplicate')

    const explore = await this.diagramModule.diagramsExplore({
      diagramId: diagram.id,
      landscapeId: this.currentLandscape.id,
      modelId: diagram.modelId,
      versionId: this.currentVersion.id
    })
    if (explore) {
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramAction({
          action: 'zoom',
          diagramId: explore.diagram.id,
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        })
      })

      this.$router.push({
        name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
        params: {
          landscapeId: this.currentLandscapeId,
          versionId: this.currentVersionId
        },
        query: {
          comment: undefined,
          connection: undefined,
          diagram: explore.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: explore.modelObject.handleId,
          object: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        }
      })
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })

    this.alertModule.pushAlert({
      message: 'Diagram duplicated'
    })

    this.$emit('duplicate')

    analytics.diagramCreate.track(this, {
      diagramType: diagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
    editorAnalytics.editorDuplicateDiagram.track(this, {
      diagramType: diagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }
}
