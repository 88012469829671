
import { StatsUser } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { getDateKeys } from '@/helpers/date-keys'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'DiagramDate'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get diagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get name () {
    return this.diagram.name
  }

  get lastUser () {
    const { monthStartAtKey, todayStartAtKey } = getDateKeys()
    return this.getLastUser(this.diagram.stats.edits.day[todayStartAtKey]?.users ?? {}) || this.getLastUser(this.diagram.stats.edits.month[monthStartAtKey]?.users ?? {}) || this.getLastUser(this.diagram.stats.edits.all.users)
  }

  getLastUser (users: Record<string, StatsUser>) {
    return Object.values(users).reduce((p, c) => !p || c.updatedAt > p.updatedAt ? c : p, null as StatsUser | null)
  }
}
